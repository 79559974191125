import React from 'react'
import { Typography } from '@mui/material'
import useStyles from '../styles'

const Introduction = () => {
  const classes = useStyles()
  const English = (
    <div className={classes.root}>
      <Typography variant='h4' className={classes.title}>
        Welcome to Hutupia
      </Typography>
      <Typography variant='body1'>
        Hutupia is a dynamic social media platform designed to foster
        collaboration and innovation. Our mission is to create a more creative
        environment where people can connect with others who have similar
        interests and skills to build something meaningful and impactful.
      </Typography>
      <Typography variant='body1' style={{ marginTop: 16 }}>
        Whether you're an entrepreneur, freelancer, or just looking to connect
        with others in your field, Hutupia offers a range of features to help
        you achieve your goals. You can post and share content with your team or
        network, chat with colleagues and clients, comment on posts and share
        feedback, share links and images, invest in projects and businesses,
        share resources and collaborate on projects, find jobs and hire
        employees, manage your company and projects, and even contract with
        other people.
      </Typography>
      <Typography variant='body1' style={{ marginTop: 16 }}>
        One of the key features of Hutupia is its ability to allow people to
        bring their ideas to the platform and collaborate with others to turn
        those ideas into reality. By connecting with others who have similar
        interests and skills, you can work together to create something that is
        meaningful and impactful.
      </Typography>
      <Typography variant='body1' style={{ marginTop: 16 }}>
        Another important feature of Hutupia is its ability to help you manage
        your company and associated activities virtually. You can create and
        manage virtual teams, share resources, and work together on projects
        from anywhere in the world. This makes it easier for you to work
        together on projects that may have been difficult or impossible to do in
        the past due to geographical restrictions.
      </Typography>
      <Typography variant='body1' style={{ marginTop: 16 }}>
        Hutupia is a platform designed to empower people and enable them to
        create things that have a positive impact on the world. By providing a
        collaborative environment and the tools needed to turn ideas into
        reality, Hutupia is helping to push the boundaries of human work and
        jobs, and create a future where creativity and innovation are at the
        forefront of everything we do. Join us today and start building your
        future with Hutupia.
      </Typography>
    </div>
  )
  const Arabic = (
    <div className={classes.root}>
      <Typography variant='h4' className={classes.title}>
        مرحبًا بك في هيوتوبيا
      </Typography>
      <Typography variant='body1'>
        هيوتوبيا هي منصة إجتماعية ديناميكية مصممة لتعزيز التعاون والإبتكار.
        مهمتنا هي خلق بيئة أكثر إبداعية حيث يمكن للأشخاص التواصل مع الآخرين
        الذين يمتلكون نفس الإهتمامات والمهارات لبناء شيء معنوي وذو تأثير.
      </Typography>
      <Typography variant='body1' style={{ marginTop: 16 }}>
        سواء كنت رائد أعمال أو مستقل أو تبحث فقط عن التواصل مع الآخرين في مجالك،
        توفر هيوتوبيا مجموعة من الميزات لمساعدتك في تحقيق أهدافك. يمكنك نشر
        ومشاركة المحتوى مع فريقك أو شبكتك، الدردشة مع الزملاء والعملاء، التعليق
        على المنشورات ومشاركة التعليقات، مشاركة الروابط والصور، الإستثمار في
        المشاريع والشركات، مشاركة الموارد والتعاون على المشاريع، البحث عن وظائف
        وتوظيف الموظفين، إدارة شركتك والمشاريع الخاصة بك، وحتى التعاقد مع
        الآخرين.
      </Typography>
      <Typography variant='body1' style={{ marginTop: 16 }}>
        أحد الميزات الرئيسية في هيوتوبيا هي قدرتها على السماح للأشخاص بإحضار
        أفكارهم إلى المنصة والتعاون مع الآخرين لتحويل تلك الأفكار إلى واقع. من
        خلال الإتصال بالآخرين الذين لديهم نفس الإهتمامات والمهارات، يمكنك العمل
        معًا لإنشاء شيء معنوي وذو تأثير.
      </Typography>
      <Typography variant='body1' style={{ marginTop: 16 }}>
        ميزة أخرى مهمة في هيوتوبيا هي قدرتها على مساعدتك في إدارة شركتك والأنشطة
        المرتبطة بها عبر الإنترنت. يمكنك إنشاء وإدارة فرق افتراضية، ومشاركة
        الموارد، والعمل معًا على المشاريع من أي مكان في العالم. هذا يجعل من
        الأسهل بالنسبة لك العمل معًا على المشاريع التي كان من الصعب أو المستحيل
        العمل عليها في الماضي بسبب القيود الجغرافية.
      </Typography>
      <Typography variant='body1' style={{ marginTop: 16 }}>
        هيوتوبيا هي منصة مصممة لتمكين الأشخاص وتمكينهم من إنشاء أشياء لها تأثير
        إيجابي على العالم. من خلال توفير بيئة تعاونية والأدوات اللازمة لتحويل
        الأفكار إلى واقع، تساعد هيوتوبيا على دفع حدود عمل الإنسان والوظائف، وخلق
        مستقبل حيث يكون الإبداع والإبتكار في الصدارة في كل ما نقوم به. إنضم
        إلينا اليوم وابدأ في بناء مستقبلك مع هيوتوبيا.
      </Typography>
    </div>
  )
  const rtl = document.body.dir === 'rtl'
  return rtl ? Arabic : English
}

export default Introduction
