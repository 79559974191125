import FirstSteps from './HutupiaGuidePageConfigsComps/GettingStarted/FirstSteps'
import Introduction from './HutupiaGuidePageConfigsComps/GettingStarted/Introduction'
import ProjectsElements from './HutupiaGuidePageConfigsComps/Hutupia Elements/ProjectsElements '
import UserProfileElements from './HutupiaGuidePageConfigsComps/Hutupia Elements/UserProfileElements'
import ChatsSystem from './HutupiaGuidePageConfigsComps/PlatformTools/ChatsSystem '
import ContributorsAndFollowers from './HutupiaGuidePageConfigsComps/PlatformTools/ContributorsAndFollowers '
import FeedsSystem from './HutupiaGuidePageConfigsComps/PlatformTools/FeedsSystem'
import HashtaggingAndMentioning from './HutupiaGuidePageConfigsComps/PlatformTools/HashtaggingAndMentioning'
import NotificationsSystems from './HutupiaGuidePageConfigsComps/PlatformTools/NotificationsSystems '
import Portfolios from './HutupiaGuidePageConfigsComps/PlatformTools/Portfolios '
import ProfileManagement from './HutupiaGuidePageConfigsComps/PlatformTools/ProfileManagement '

const rtl = document.body.dir === 'rtl'
const HutupiaGuidePageConfigs = [
  {
    id: 1,
    title: rtl ? 'ابدأ' : 'Getting Started',
    subtitles: [
      {
        id: 1,
        title: rtl ? 'مقدمة' : 'Introduction',
        content: <Introduction />,
      },
      {
        id: 2,
        title: rtl ? 'الخطوات الأولى' : 'First Steps',
        content: <FirstSteps />,
      },
    ],
  },
  {
    id: 2,
    title: rtl ? 'أدوات منصة' : 'Platform Tools',
    subtitles: [
      {
        id: 1,
        title: rtl ? 'إدارة الحساب' : 'Managing Your Profile',
        content: <ProfileManagement />,
      },
      {
        id: 2,
        title: rtl ? 'المساهمون والمتابعون' : 'Contributors and Followers',
        content: <ContributorsAndFollowers />,
      },
      {
        id: 3,
        title: rtl ? 'ملفات تعريفية' : 'Portfolios',
        content: <Portfolios />,
      },
      {
        id: 4,
        title: rtl ? 'الهاشتاج والإشارة' : 'Hashtagging and Mentioning',
        content: <HashtaggingAndMentioning />,
      },
      {
        id: 5,
        title: rtl ? 'نظام المحادثات' : 'Chats System',
        content: <ChatsSystem />,
      },
      {
        id: 6,
        title: rtl ? 'انظمة التغذية و الأخبار' : 'Feeds Systems',
        content: <FeedsSystem />,
      },
      {
        id: 7,
        title: rtl ? 'أنظمة الإشعارات' : 'Notifications Systems',
        content: <NotificationsSystems />,
      },
    ],
  },
  {
    id: 3,
    title: rtl ? 'عناصر هيوتيوبيا' : 'Hutupia Elements',
    subtitles: [
      {
        id: 1,
        title: rtl ? 'عناصر المشروعات' : 'Projects Elements',
        content: <ProjectsElements />,
      },
      {
        id: 2,
        title: rtl ? 'عناصر حساب المستخدم' : 'User Profile Elements',
        content: <UserProfileElements />,
      },
    ],
  },
]

export default HutupiaGuidePageConfigs
