import React from 'react'
import { Typography } from '@mui/material'
import useStyles from '../styles'

const HashtaggingAndMentioning = () => {
  const classes = useStyles()
  const English = (
    <div className={classes.root}>
      <Typography variant='h2' className={classes.title}>
        Hashtagging and Mentioning
      </Typography>
      <Typography variant='body1'>
        When working with most inputs in Hutupia, you can mention other users by
        typing the @ symbol followed by their name, and then selecting their
        name from the emergent list. Hashtagging using the @ symbol will direct
        you to the profile of the mentioned user upon clicking.
      </Typography>
      <Typography variant='body1'>
        For specific elements in Hutupia, such as Products, Tasks, Campaigns,
        Jobs, Business Model Templates, Resources, Issues, Ideas, Inquiries,
        Global Inquiries, Marketing Strategy Aspects, Currently Opened Project
        Members, Products Strategies, Research Activities, Charted Data, Product
        Designs, Partnership Channels, and PDFs, you can use the $ symbol for
        mentioning. Once clicked, a window will appear showing the profile of
        the mentioned element.
      </Typography>
      <Typography variant='body1'>
        Hashtagging in Hutupia is not limited to specific types of elements and
        can be used more generally, like in social media. You can use the #
        symbol to add tags to your content. Clicking on a hashtagged word will
        open the search system and provide information about the number of
        hashtags associated with that specific word.
      </Typography>
    </div>
  )
  const Arabic = (
    <div className={classes.root}>
      <Typography variant='h2' className={classes.title}>
        الهاشتاج والإشارة
      </Typography>
      <Typography variant='body1'>
        عند العمل مع معظم الإدخالات في هيوتيوبيا، يمكنك الإشارة إلى مستخدمين
        آخرين عن طريق كتابة الرمز @ تليه اسمهم، ثم اختيار اسمهم من القائمة.
        الهاشتاج باستخدام الرمز @ سيوجهك إلى الملف الشخصي للمستخدم المشار إليه
        عند النقر عليه.
      </Typography>
      <Typography variant='body1'>
        بالنسبة لعناصر محددة في هيوتيوبيا مثل المنتجات والمهام والحملات والوظائف
        وقوالب نموذج الأعمال والموارد والمشكلات والأفكار والاستفسارات
        والاستفسارات العالمية وجوانب استراتيجية التسويق وأعضاء المشروع المفتوح
        حاليًا واستراتيجيات المنتجات وأنشطة البحث والبيانات المرسومة وتصميمات
        المنتجات وقنوات الشراكة وملفات PDF، يمكنك استخدام الرمز $ للإشارة إليها.
        بمجرد النقر، سيظهر نافذة تعرض الملف الشخصي للعنصر المشار إليه.
      </Typography>
      <Typography variant='body1'>
        الهاشتاج في هيوتيوبيا ليس محدودًا لأنواع العناصر المحددة، بل يمكن
        استخدامه بشكل أكثر عمومية كما في وسائل التواصل الاجتماعي. يمكنك استخدام
        الرمز # لإضافة وسوم إلى محتواك. النقر على كلمة بهاشتاج سيفتح نظام البحث
        ويعرض معلومات حول عدد الوسوم المرتبطة بهذه الكلمة المحددة.
      </Typography>
    </div>
  )

  const rtl = document.body.dir === 'rtl'
  return rtl ? Arabic : English
}

export default HashtaggingAndMentioning
