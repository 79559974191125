import React from 'react'
import { Typography } from '@mui/material'

const Portfolios = () => {
    const English = (
      <div>
        <Typography variant='h2' align='center' fontWeight='bold' mb={2}>
          Portfolios
        </Typography>
        <Typography variant='body1' align='justify'>
          Hutupia provides two types of portfolios: user portfolios and project
          portfolios. The user portfolio serves as a summary of a user's
          activity and ranking on the platform. It offers valuable information
          about a user's behavior and trustworthiness, as well as access to
          published ideas, inquiries, and other elements.
        </Typography>
        <Typography variant='body1' align='justify'>
          Project portfolios, on the other hand, represent a project's rank and
          activity. They provide insight into the project's progress,
          contributions, and impact. By having both types of portfolios
          available, Hutupia enables users to showcase their skills and
          experience while also keeping track of their progress and performance
          on the platform.
        </Typography>
      </div>
    )

    const Arabic = (
      <div>
        <Typography variant='h2' align='center' fontWeight='bold' mb={2}>
          ملفات تعريفية
        </Typography>
        <Typography variant='body1' align='justify'>
          توفر هيوتيوبيا نوعين من ملفات التعريف الخاصة بالمستخدمين: ملفات تعريف
          المستخدمين وملفات تعريف المشاريع. تعمل ملفات تعريف المستخدمين كملخص
          لنشاط المستخدم وترتيبه على المنصة. وتوفر معلومات قيمة عن سلوك المستخدم
          ومدى ثقته، بالإضافة إلى الوصول إلى الأفكار المنشورة والاستفسارات
          وعناصر أخرى.
        </Typography>
        <Typography variant='body1' align='justify'>
          من ناحية أخرى، تمثل ملفات تعريف المشاريع ترتيب المشروع ونشاطه. حيث
          توفر نظرة شاملة على تقدم المشروع ومساهماته وتأثيره. بوجود كلا النوعين
          من الملفات التعريفية المتاحة، يمكن لمستخدمي هيوتيوبيا عرض مهاراتهم
          وخبراتهم، بالإضافة إلى تتبع تقدمهم وأدائهم على المنصة.
        </Typography>
      </div>
    )
    const rtl = document.body.dir === 'rtl'
    return rtl ? Arabic : English
}

export default Portfolios
