import React from 'react'
import { Typography } from '@mui/material'
import useStyles from '../styles'

const UserProfileElements = () => {
  const classes = useStyles()
  const English = (
    <div className={classes.root}>
      <Typography variant='h3' className={classes.subTitle}>
        Ideas
      </Typography>
      <Typography variant='body1'>
        Users can create, manage, and refine their ideas on Hutupia. They can
        share their ideas with others while controlling who can view or
        contribute to them. Each idea has its own channel of contribution to
        facilitate interactions and collaboration.
      </Typography>
      <Typography variant='h3' className={classes.subTitle}>
        Inquiries
      </Typography>
      <Typography variant='body1'>
        The inquiry feature enables users to outsource their requirements and
        needs before starting a project or developing an idea. Other users can
        interact with the inquiries and offer feedback or assistance. Inquiries
        also provide a platform for users to trade their skills and abilities to
        accomplish tasks.
      </Typography>
      <Typography variant='h3' className={classes.subTitle}>
        Global Inquiries
      </Typography>
      <Typography variant='body1'>
        Global inquiries extend the reach of inquiries across the Hutupia
        community. They enable users to solicit feedback and ideas from a
        broader audience, providing a powerful tool for developing new
        approaches or services. Global inquiries also offer a place for projects
        to find opportunities, markets, and products.
      </Typography>
      <Typography variant='h3' className={classes.subTitle}>
        Business Models
      </Typography>
      <Typography variant='body1'>
        Users can consult the business models featured in idea forms or profiles
        to standardize their ideas and compare them with successful industries.
        The business models serve as a standard communication tool among Hutupia
        users, promoting consistency and clarity in idea development and
        implementation.
      </Typography>
    </div>
  )
  const Arabic = (
    <div className={classes.root}>
      <Typography variant='h2' className={classes.title}>
        عناصر ملف المستخدم
      </Typography>
      <Typography variant='h3' className={classes.subTitle}>
        الأفكار
      </Typography>
      <Typography variant='body1'>
        يمكن للمستخدمين إنشاء أفكارهم وإدارتها وتحسينها على Hutupia. يمكنهم
        مشاركة أفكارهم مع الآخرين مع السيطرة على من يمكنه عرضها أو المساهمة
        فيها. يتميز كل فكرة بقناة خاصة بها لتيسير التفاعلات والتعاون.
      </Typography>
      <Typography variant='h3' className={classes.subTitle}>
        متطلبات
      </Typography>
      <Typography variant='body1'>
        تتيح ميزة المتطلبات للمستخدمين تسويق متطلباتهم واحتياجاتهم قبل بدء مشروع
        أو تطوير فكرة. يمكن للمستخدمين الآخرين التفاعل مع المتطلبات وتقديم
        ملاحظات أو مساعدة. توفر المتطلبات أيضًا منصة للمستخدمين لتداول مهاراتهم
        وقدراتهم لإنجاز المهام.
      </Typography>
      <Typography variant='h3' className={classes.subTitle}>
        الاستفسارات العامة
      </Typography>
      <Typography variant='body1'>
        تمتد الاستفسارات العامة لتشمل مجتمع Hutupia بأكمله. تتيح للمستخدمين جمع
        الملاحظات والأفكار من جمهور أوسع، مما يوفر أداة قوية لتطوير نهج أو خدمة
        جديدة. توفر الاستفسارات العامة أيضًا مكانًا للمشاريع للعثور على فرص
        وأسواق ومنتجات.
      </Typography>
      <Typography variant='h3' className={classes.subTitle}>
        نماذج الأعمال
      </Typography>
      <Typography variant='body1'>
        يمكن للمستخدمين الاطلاع على نماذج الأعمال الموجودة في صفحات الأفكار أو
        الملف الشخصي لتوحيد أفكارهم ومقارنتها بالصناعات الناجحة. تعد نماذج
        الأعمال أيضًا أداة اتصال قياسية بين مستخدمي Hutupia، مما يعزز التوافق
        والوضوح في تطوير وتنفيذ الأفكار.
      </Typography>
    </div>
  )
  const rtl = document.body.dir === 'rtl'
  return rtl ? Arabic : English
}

export default UserProfileElements
