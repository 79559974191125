import React from 'react'
import { Typography, List, ListItem } from '@mui/material'
import useStyles from '../styles'

const ProfileManagement = () => {
  const classes = useStyles()

  const English = (
    <div className={classes.root}>
      <Typography variant='h2' className={classes.title}>
        Managing Your Profile
      </Typography>
      <Typography variant='body1'>
        Your profile is an important part of your presence on the platform. Here
        are some things you can do to manage it:
      </Typography>
      <List>
        <ListItem className={classes.listItem}>
          <Typography variant='body1'>
            Provide more data in the general project settings: You can add more
            information about yourself, such as your skills, experience, and
            interests. This will help other users find you and collaborate with
            you on projects.
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant='body1'>
            Update your profile data: You should keep your profile up-to-date,
            including your contact information, profile picture, and bio. This
            will help other users learn more about you and stay in touch.
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant='body1'>
            Control all ideas, inquiries, global inquiries, resource channels,
            contract projects, and more: You can manage all the content related
            to your profile, such as the ideas and inquiries you've posted, the
            resource channels you've created, and the contract projects you're
            working on.
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant='body1'>
            Manage feed posts and more: You can also manage your feed posts,
            such as the ones you've posted, liked, or commented on, as well as
            your notifications and settings.
          </Typography>
        </ListItem>
      </List>
    </div>
  )
  const Arabic = (
    <div className={classes.root}>
      <Typography variant='h2' className={classes.title}>
        إدارة ملفك الشخصي
      </Typography>
      <Typography variant='body1'>
        يعد ملفك الشخصي جزءًا هامًا من وجودك على المنصة. وفيما يلي بعض الأشياء
        التي يمكنك القيام بها لإدارته:
      </Typography>
      <List>
        <ListItem className={classes.listItem}>
          <Typography variant='body1'>
            توفير المزيد من البيانات في إعدادات المشروع العامة: يمكنك إضافة مزيد
            من المعلومات حول نفسك ، مثل مهاراتك وخبراتك واهتماماتك. وسيساعد هذا
            المستخدمين الآخرين على العثور عليك والتعاون معك في المشاريع.
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant='body1'>
            تحديث بيانات ملفك الشخصي: يجب عليك الحفاظ على ملفك الشخصي محدثًا ،
            بما في ذلك معلومات الاتصال الخاصة بك وصورة ملفك الشخصي ونبذة عنك.
            وسيساعد هذا المستخدمين الآخرين على معرفة المزيد عنك والبقاء على
            اتصال.
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant='body1'>
            التحكم في جميع الأفكار والاستفسارات والاستفسارات العامة وقنوات
            الموارد ومشاريع العقود والمزيد: يمكنك إدارة جميع المحتوى المتعلق
            بملفك الشخصي ، مثل الأفكار والاستفسارات التي قمت بنشرها وقنوات
            الموارد التي قمت بإنشائها ومشاريع العقود التي تعمل عليها.
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant='body1'>
            إدارة المنشورات والتغذية العاجلة والمزيد: يمكنك أيضًا إدارة منشورات
            التغذية العاجلة الخاصة بك ، مثل تلك التي نشرتها أو أعجبت بمحتواها أو
            علقت عليها ، بالإضافة إلى إدارة إشعاراتك وإعداداتك.
          </Typography>
        </ListItem>
      </List>
    </div>
  )
  const rtl = document.body.dir === 'rtl'
  return rtl ? Arabic : English
}

export default ProfileManagement
