import React from 'react'
import { Typography } from '@mui/material'
import useStyles from '../styles'

const NotificationsSystems = () => {
  const classes = useStyles()
  const English = (
    <div className={classes.root}>
      <Typography variant='h2' className={classes.title}>
        Notifications Systems in Huutpia
      </Typography>
      <Typography variant='body1'>
        Huutpia has three different types of notification systems that serve
        different purposes:
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        General Notifications
      </Typography>
      <Typography variant='body1'>
        This system handles social interactions and feeds, such as comments,
        likes, mentions, new followers, and new additions to contribution lists.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        Contributors Notifications
      </Typography>
      <Typography variant='body1'>
        This system handles interactions between users and their projects,
        whether they are created or contracted. It manages all the interactions
        and actions that occur within the projects, and notifications are
        delivered to the user based on their role in the project and the
        activities associated with them.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        Contracts Notifications
      </Typography>
      <Typography variant='body1'>
        This system, as the name suggests, manages contracts, including
        creation, updates, terminations, and associated actions.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        Notification Body Management
      </Typography>
      <Typography variant='body1'>
        The notification body is managed to handle interactions between users
        properly and easily. It provides direct buttons to establish a standard
        effective response and access to associated elements if they exist.
      </Typography>
    </div>
  )
  const Arabic = (
    <div className={classes.root}>
      <Typography variant='h2' className={classes.title}>
        نظام الإشعارات في هيوتيوبيا
      </Typography>
      <Typography variant='body1'>
        يحتوي هيوتيوبيا على ثلاثة أنواع مختلفة من نظام الإشعارات، ويخدم كل نوع
        منها غرضًا مختلفًا:
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        الإشعارات العامة
      </Typography>
      <Typography variant='body1'>
        يتولى هذا النظام التفاعلات الاجتماعية وتغذيات المستخدمين، مثل التعليقات
        والإعجابات والإشارات والمتابعين الجدد والإضافات الجديدة إلى قوائم
        المساهمات.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        إشعارات المساهمين
      </Typography>
      <Typography variant='body1'>
        يتولى هذا النظام التفاعلات بين المستخدمين ومشاريعهم، سواء كانت المشاريع
        التي قاموا بإنشائها أو التي تعاقدوا عليها. يدير هذا النظام جميع
        التفاعلات والإجراءات التي تحدث داخل المشاريع، ويتم تسليم الإشعارات إلى
        المستخدم بناءً على دوره في المشروع والأنشطة المرتبطة به.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        إشعارات العقود
      </Typography>
      <Typography variant='body1'>
        يدير هذا النظام، كما يوحي اسمه، العقود، بما في ذلك الإنشاء والتحديث
        والإنهاء والإجراءات المرتبطة بها.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        إدارة جسم الإشعارات
      </Typography>
      <Typography variant='body1'>
        يتم إدارة جسم الإشعارات للتعامل مع التفاعلات بين المستخدمين بشكل صحيح
        وسهل، حيث يتم توفير أزرار مباشرة لإنشاء رد فعل قياسي فعال ووصول إلى
        العناصر المرتبطة إذا وجدت.
      </Typography>
    </div>
  )
  const rtl = document.body.dir === 'rtl'
  return rtl ? Arabic : English
}

export default NotificationsSystems
