import React from 'react'
import { Typography } from '@mui/material'
import useStyles from '../styles'

const FeedsSystem = () => {
  const classes = useStyles()
const English = (
  <div className={classes.root}>
    <Typography variant='h2' className={classes.title}>
      Feeds System
    </Typography>
    <Typography variant='body1'>
      The Feeds System in Hutupia is a platform that allows users to access
      different types of news feeds. These feeds are designed to cater to the
      specific needs and interests of users and provide a space for social
      interactions, job and task outsourcing, resource sharing, investment and
      partnership channels, and user-generated content.
    </Typography>
    <Typography variant='body1' className={classes.subtitle}>
      Social Interaction Feeds
    </Typography>
    <Typography variant='body1'>
      Social interaction feeds are the general feeds that users can access to
      interact with other users. Users can create posts, share images, and
      links. They can also use hashtags to make their posts more discoverable.
      The content in these feeds is not limited to any specific topic or
      industry, allowing users to connect with a diverse range of people.
    </Typography>
    <Typography variant='body1' className={classes.subtitle}>
      Job and Task Outsourcing Feeds
    </Typography>
    <Typography variant='body1'>
      The job and task outsourcing feeds in Hutupia provide a platform for
      freelancers and employers to connect. Employers can post jobs, and
      freelancers can apply for them. The feeds also allow users to share their
      skills and services, making it easier for potential clients to find them.
    </Typography>
    <Typography variant='body1' className={classes.subtitle}>
      Resource Sharing Feeds
    </Typography>
    <Typography variant='body1'>
      Resource sharing feeds in Hutupia allow users to share resources with
      other users. These resources can include anything from documents and tools
      to tips and advice. The feeds are designed to promote collaboration and
      knowledge-sharing among users.
    </Typography>
    <Typography variant='body1' className={classes.subtitle}>
      Investment and Partnership Channels Feeds
    </Typography>
    <Typography variant='body1'>
      The investment and partnership channels feeds in Hutupia provide a
      platform for users to connect with potential partners and investors. Users
      can share information about their products and services, as well as their
      investment needs and opportunities. The feeds are designed to facilitate
      partnerships and collaborations among users.
    </Typography>
    <Typography variant='body1' className={classes.subtitle}>
      User-Generated Content Feeds
    </Typography>
    <Typography variant='body1'>
      User-generated content feeds in Hutupia allow users to share their ideas
      and inquiries with the community. Users can create posts and share their
      thoughts on various topics, such as technology, business, and culture. The
      feeds are designed to foster creativity and innovation among users.
    </Typography>
    <Typography variant='body1' className={classes.subtitle}>
      Global Inquiries Feeds
    </Typography>
    <Typography variant='body1'>
      The global inquiries feeds in Hutupia provide a platform for users to
      share their inquiries and ideas with a wider audience. Users can make
      their posts visible to users in the same region, such as their country, to
      increase their reach and engagement. The feeds are designed to promote
      cross-cultural dialogue and understanding among users.
    </Typography>
    <Typography variant='body1' className={classes.subtitle}>
      Project News and Activities Feeds
    </Typography>
    <Typography variant='body1'>
      The project news and activities feeds in Hutupia provide a platform for
      users to stay updated on the latest news and activities related to
      projects. Users can view posts related to project updates, events, and
      milestones. The feeds are designed to keep users informed and engaged with
      the projects they are interested in.
    </Typography>
    <Typography variant='body1' className={classes.subtitle}>
      User Profile Feeds
    </Typography>
    <Typography variant='body1'>
      The user profile feeds in Hutupia allow users to view their own posts and
      activity on the platform. Users can view their posts, comments, likes, and
      shares in one place. The feeds are designed to help users keep track of
      their activity on the platform and stay organized.
    </Typography>
    <Typography variant='body1' className={classes.subtitle}>
      Project Profile Feeds
    </Typography>
    <Typography variant='body1'>
      The project profile feeds in Hutupia allow users to view the posts and
      activity related to a specific project. Users can view posts related to
      the project's updates, events, and milestones. The feeds are designed to
      keep users informed and engaged with the projects they are interested in.
    </Typography>
    <Typography variant='body1'>
      In addition to creating posts, users can also share profiles of different
      elements in the form of posts. These posts can include idea posts or
      inquiry posts and will show a card that can access a window with
      additional information about the profile.
    </Typography>
  </div>
)
const Arabic = (
  <div className={classes.root}>
    <Typography variant='h2' className={classes.title}>
      نظام التغذية في هيوتيوبيا
    </Typography>
    <Typography variant='body1'>
      يعد نظام التغذية في هيوتيوبيا منصة تتيح للمستخدمين الوصول إلى أنواع مختلفة
      من تغذيات الأخبار. تم تصميم هذه التغذيات لتلبية احتياجات واهتمامات
      المستخدمين الفردية وتوفير مساحة للتفاعل الاجتماعي وتوظيف العمل والمهام
      ومشاركة الموارد وقنوات الاستثمار والشراكة والمحتوى الذي يقوم المستخدمون
      بإنشائه.
    </Typography>
    <Typography variant='body1' className={classes.subtitle}>
      تغذيات التفاعل الاجتماعي
    </Typography>
    <Typography variant='body1'>
      تعد تغذيات التفاعل الاجتماعي هي التغذيات العامة التي يمكن للمستخدمين
      الوصول إليها للتفاعل مع المستخدمين الآخرين. يمكن للمستخدمين إنشاء مشاركات
      ومشاركة الصور والروابط. كما يمكنهم استخدام الوسوم لجعل مشاركاتهم أكثر
      قابلية للعثور عليها. لا تقتصر محتويات هذه التغذيات على أي موضوع أو صناعة
      محددة، مما يتيح للمستخدمين التواصل مع مجموعة متنوعة من الأشخاص.
    </Typography>
    <Typography variant='body1' className={classes.subtitle}>
      تغذيات توظيف العمل والمهام
    </Typography>
    <Typography variant='body1'>
      توفر تغذيات توظيف العمل والمهام في هيوتيوبيا منصة للعاملين الحر وأصحاب
      العمل للتواصل. يمكن لأصحاب العمل نشر وظائف، ويمكن للعمال الحر التقدم لها.
      كما تتيح التغذيات للمستخدمين مشاركة مهاراتهم وخدماتهم، مما يجعل من السهل
      على العملاء المحتملين العثور عليهم.
    </Typography>
    <Typography variant='body1' className={classes.subtitle}>
      تغذيات مشاركة الموارد
    </Typography>
    <Typography variant='body1'>
      تتيح تغذيات مشاركة الموارد في هيوتيوبيا للمستخدمين مشاركة الموارد مع
      المستخدمين الآخرين. يمكن أن تشمل هذه الموارد أي شيء من وثائق وأدوات إلى
      نصائح ومشورة. تم تصميم هذه التغذيات لتعزيز التعاون ومشاركة المعرفة بين
      المستخدمين.
    </Typography>
    <Typography variant='body1' className={classes.subtitle}>
      تغذيات قنوات الاستثمار والشراكة
    </Typography>
    <Typography variant='body1'>
      تعد تغذيات قنوات الاستثمار والشراكة في هيوتيوبيا منصة للمستثمرين وأصحاب
      الأعمال لتوفير فرص الاستثمار والشراكة. يمكن للمستخدمين تقديم العروض
      والطلبات والتواصل مع الأشخاص الذين يمتلكون نفس الاهتمامات والأهداف. هذه
      التغذيات توفر فرصة للمستخدمين للتعاون وتحقيق النجاح معًا.
    </Typography>
    <Typography variant='body1' className={classes.subtitle}>
      تغذيات المحتوى الذي ينشئه المستخدمون
    </Typography>
    <Typography variant='body1'>
      يمكن للمستخدمين إنشاء محتوى خاص بهم في هيوتيوبيا ومشاركته مع المستخدمين
      الآخرين. يمكن أن يتضمن هذا المحتوى أي شيء من النصوص والصور ومقاطع الفيديو.
      يمكن للمستخدمين استخدام هذه التغذيات لمشاركة خبراتهم مع المجتمع وتوظيفها
      لتحقيق النجاح. كما يمكن للمستخدمين استخدام هذه التغذيات للترويج لعلاماتهم
      التجارية وخدماتهم.
    </Typography>
  </div>
)
   const rtl = document.body.dir === 'rtl'
   return rtl ? Arabic : English
}

export default FeedsSystem
