import React, { useState } from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Home as HomeIcon, Info as InfoIcon } from '@material-ui/icons'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    justifyContent: 'center',
    height: '64px',
    ...theme.mixins.toolbar,
  },
  section: {
    backgroundColor: theme.palette.grey[100],
    '&$activeSection': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  },
  activeSection: {},
  subsection: {
    backgroundColor: theme.palette.grey[200],
    '&$activeSubsection': {
      backgroundColor: theme.palette.grey[300],
    },
    fontSize: '0.9rem',
    paddingLeft: theme.spacing(3),
  },
}))

const HutupiaGuidePageSidebar = ({
  sections,
  activeSection,
  activeSubsection,
  onSectionClick,
  onSubsectionClick,
  handleDrawerClose,
}) => {
  const classes = useStyles()
  const [searchTerm, setSearchTerm] = useState('')
  const rtl = document.body.dir === 'rtl'

  const handleMenuItemClick = (sectionIndex, subsectionIndex) => {
    onSectionClick(sectionIndex)
    onSubsectionClick(subsectionIndex)
    handleDrawerClose()
  }

  const handleSectionClick = (sectionIndex) => {
    if (activeSection === sectionIndex) {
      onSectionClick(null)
    } else {
      onSectionClick(sectionIndex)
      onSubsectionClick(null)
    }
  }

  const filterSubtitles = (subtitles) => {
    return subtitles.filter(
      (subtitle) =>
        subtitle.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        subtitle.content.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }

  const filteredSections = sections.filter((section) => {
    const filteredSubtitles = filterSubtitles(section.subtitles)
    return (
      filteredSubtitles.length > 0 ||
      section.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })

  return (
    <div>
      <div className={classes.toolbar}>
        <h3> {rtl ? 'ارشادات هيوتيوبيا' : 'Hutupia Guide'}</h3>
      </div>
      {/* <TextField
        label='Search'
        variant='outlined'
        fullWidth
        margin='normal'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      /> */}
      <List>
        {filteredSections.map((section, sectionIndex) => (
          <div key={section.title}>
            <ListItem
              button
              className={`${classes.section} ${
                activeSection === sectionIndex && classes.activeSection
              }`}
              onClick={() => handleSectionClick(sectionIndex)}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={section.title} />
              {activeSection === sectionIndex ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </ListItem>
            {activeSection === sectionIndex && (
              <List>
                {filterSubtitles(section.subtitles).map(
                  (subtitle, subsectionIndex) => {
                    if (subtitle.sectionIndex !== null) {
                      return (
                        <ListItem
                          button
                          key={subtitle.title}
                          className={`${classes.subsection} ${
                            activeSubsection === subsectionIndex &&
                            classes.activeSubsection
                          }`}
                          onClick={(e) => {
                            e.preventDefault()
                            handleMenuItemClick(sectionIndex, subsectionIndex)
                          }}
                        >
                          <ListItemIcon>
                            <InfoIcon />
                          </ListItemIcon>
                          <ListItemText primary={subtitle.title} />
                          {rtl ? (
                            <ChevronLeftIcon fontSize='small' />
                          ) : (
                            <ChevronRightIcon fontSize='small' />
                          )}
                        </ListItem>
                      )
                    } else {
                      return null
                    }
                  }
                )}
              </List>
            )}
          </div>
        ))}
      </List>
    </div>
  )
}

export default HutupiaGuidePageSidebar
