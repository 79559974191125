import React from 'react'
import { Typography, List, ListItem } from '@mui/material'
import useStyles from '../styles'

const FirstSteps = () => {
  const classes = useStyles()
  const English = (
    <div className={classes.root}>
      <Typography variant='body1'>
        To get started, please follow these instructions:
      </Typography>
      <List>
        <ListItem className={classes.listItem}>
          <Typography variant='body1'>
            Create a new account by entering the required data.
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant='body1'>
            Allow the browser to determine your location when prompted. This
            will help us customize the content for you. Please allow it at least
            once.
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant='body1'>
            After creating your account, you will need to verify your email
            address to gain access to the platform. If you did not verify your
            email during the sign-up process, you will be prompted to do so when
            you try to log in for the first time.
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant='body1'>
            Please note that no one is allowed to enter Hutupia before verifying
            their email address.
          </Typography>
        </ListItem>
      </List>
    </div>
  )

  const Arabic = (
    <div className={classes.root}>
      <Typography variant='body1'>للبدء ، يرجى اتباع هذه التعليمات:</Typography>
      <List>
        <ListItem className={classes.listItem}>
          <Typography variant='body1'>
            قم بإنشاء حساب جديد عن طريق إدخال البيانات المطلوبة.
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant='body1'>
            سيُطلب منك السماح للمتصفح بتحديد موقعك الجغرافي عندما تظهر الرسالة.
            وسيساعد ذلك في تخصيص المحتوى بالنسبة لك. يرجى السماح به مرة واحدة
            على الأقل.
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant='body1'>
            بعد إنشاء حسابك ، ستحتاج إلى التحقق من عنوان بريدك الإلكتروني للوصول
            إلى المنصة. إذا لم تقم بالتحقق من البريد الإلكتروني الخاص بك أثناء
            عملية التسجيل ، سيتم طلب ذلك عندما تحاول تسجيل الدخول للمرة الأولى.
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant='body1'>
            يرجى ملاحظة أنه لا يسمح لأي شخص بالدخول إلى هوتوبيا قبل التحقق من
            عنوان بريدهم الإلكتروني.
          </Typography>
        </ListItem>
      </List>
    </div>
  )
  const rtl = document.body.dir === 'rtl'
  return rtl ? Arabic : English
}

export default FirstSteps
