import React from 'react'
import { Typography } from '@mui/material'
import useStyles from '../styles'

const ProjectsElements = () => {
  const classes = useStyles()
  const English = (
    <div className={classes.root}>
      <Typography variant='h2' className={classes.title}>
        Projects Elements
      </Typography>
      <Typography variant='body1'>
        Most elements on the platform have their own profiles, which enables
        users to update, delete, create, or share them internally or externally
        using a link. Additionally, elements can have a partnership channel that
        is automatically created to support discussions and interactions between
        users who share or contribute to them.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        Resources
      </Typography>
      <Typography variant='body1'>
        Project resources can be introduced by the project owner or shared
        resources introduced in partnership channels involving the project.
        However, the resources are originally created by the user in their own
        profile. Typically, the resource profile has its own partnership channel
        that allows private interactions between users.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        Partnership Channels
      </Typography>
      <Typography variant='body1'>
        Partnership channels are closed private interaction channels for users
        partnering in them. They create an automatic contract between all users
        to determine their interactions and control the way their added elements
        are shared and managed. The channel allows non-members to send a
        membership request, which must be confirmed by everyone in the channel.
        If only one person refuses, the whole request is deleted. The channel
        also provides an internal feed system for interactions.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        Contracts
      </Typography>
      <Typography variant='body1'>
        Contracts hold agreements between contracting users and allow users to
        download or invoke an issue for the contract if any violation is
        triggered. There are two types of contracts: project membership
        contracts, which are created when a user is added to a new project and
        hold how the contributors will act or shares will be given in a specific
        project, and contracts associated with any channel created to control
        interactions.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        Issues
      </Typography>
      <Typography variant='body1'>
        Issues are another element that represents problems that occur between
        users due to violations of contracts. They can be raised by any user
        involved in a contract and trigger a resolution process.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        Campaigns
      </Typography>
      <Typography variant='body1'>
        Campaigns are elements that represent a method for creating campaigns to
        market project activities and provide a method for outsourcing campaigns
        with external partners. They allow for collaboration between project
        members and external partners to execute marketing campaigns.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        Investment Packages
      </Typography>
      <Typography variant='body1'>
        Investment packages are offered packages for attracting potential
        investors. They can be customized for different types of investors and
        typically include information about the project's financials,
        objectives, and potential returns.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        Financial Statements and Tools
      </Typography>
      <Typography variant='body1'>
        Financial statements and tools provide methods, along with charts, to
        illustrate and store financial information. This allows project owners
        and contributors to track financial performance and make informed
        decisions about the project's future.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        Shares Management
      </Typography>
      <Typography variant='body1'>
        Shares management is for managing shares between contributors. It allows
        project owners and contributors to track and manage shares and ensure
        that they are allocated appropriately based on each contributor's level
        of involvement and contribution to the project.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        Rooms
      </Typography>
      <Typography variant='body1'>
        There are three types of rooms in a project: the grand room for the
        whole project, the department room for only department members, and the
        subdepartment room for subdepartments in any department. Rooms allow
        discussions and navigation of discussion history, enabling project
        members to collaborate and share information effectively.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        Departments
      </Typography>
      <Typography variant='body1'>
        A project also contains departments for managing contributors and
        subdepartments within each department supported with document, product,
        and task management systems. Users can also manage meetings, votings,
        requests, internally and externally, and manage contributions with other
        projects. Additionally, the platform provides dozens of business model
        templates and marketing strategy aspects to help users standardize their
        thoughts and develop effective project plans.
      </Typography>
    </div>
  )

  const Arabic = (
    <div className={classes.root}>
      <Typography variant='h2' className={classes.title}>
        عناصر المشاريع
      </Typography>
      <Typography variant='body1'>
        معظم العناصر على المنصة لها ملفات تعريف خاصة بها، مما يتيح للمستخدمين
        تحديثها، حذفها، إنشائها، أو مشاركتها داخليًا أو خارجيًا باستخدام رابط.
        بالإضافة إلى ذلك، يمكن أن تحتوي العناصر على قناة شراكة تنشئ تلقائيًا
        لدعم المناقشات والتفاعلات بين المستخدمين الذين يشاركون أو يساهمون فيها.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        الموارد
      </Typography>
      <Typography variant='body1'>
        يمكن لمالك المشروع أن يضيف موارد المشروع أو موارد مشتركة تم تقديمها في
        قنوات الشراكة المتضمنة في المشروع. ومع ذلك، فإن الموارد تم إنشاؤها في
        الأصل من قبل المستخدم في ملفه الشخصي الخاص به. عادةً، يحتوي ملف الموارد
        الخاص به على قناة شراكة خاصة به يتيح الاتصالات الخاصة بين المستخدمين.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        قنوات الشراكة
      </Typography>
      <Typography variant='body1'>
        قنوات الشراكة هي قنوات تفاعل خاصة ومغلقة للمستخدمين الشركاء فيها. تنشئ
        عقدًا تلقائيًا بين جميع المستخدمين لتحديد تفاعلاتهم والتحكم في الطريقة
        التي يتم بها مشاركة العناصر المضافة وإدارتها. تتيح القناة لغير الأعضاء
        إرسال طلب انضمام، ويجب تأكيده من قبل جميع الأعضاء في القناة. إذا رفض شخص
        واحد فقط، فإن الطلب بأكمله يتم حذفه. كما توفر القناة نظامًا داخليًا
        للتفاعلات.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        العقود
      </Typography>
      <Typography variant='body1'>
        تحمل العقود اتفاقيات بين مستخدمي العقد وتتيح للمستخدمين تنزيل العقد أو
        استدعاء مسألة للعقد إذا تم تنشيط أي انتهاك. توجد نوعان من العقود: عقود
        عضوية المشروع، والتي يتم إنشاؤها عندما يتم إضافة مستخدم إلى مشروع جديد،
        وتحمل كيفية سلوك المساهمين أو كيفية إعطاء الحصص في مشروع محدد، والعقود
        المرتبطة بأي قناة تم إنشاؤها للتحكم في التفاعلات.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        المسائل
      </Typography>
      <Typography variant='body1'>
        تمثل المسائل عنصرًا آخر يمثل المشاكل التي تحدث بين المستخدمين بسبب
        انتهاكات للعقود. يمكن رفعها من قبل أي مستخدم مشارك في العقد وتشغيل عملية
        حل المشكلة.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        الحملات
      </Typography>
      <Typography variant='body1'>
        تمثل الحملات عناصر تمثل طريقة لإنشاء حملات تسويقية لأنشطة المشروع وتوفير
        طريقة لتفويض الحملات مع شركاء خارجيين. تسمح بالتعاون بين أعضاء المشروع
        والشركاء الخارجيين لتنفيذ حملات التسويق.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        حزم الاستثمار
      </Typography>
      <Typography variant='body1'>
        تمثل حزم الاستثمار حزم العروض التي تستخدم لجذب المستثمرين المحتملين.
        يمكن تخصيصها لأنواع مختلفة من المستثمرين وعادة ما تتضمن معلومات حول
        الأرقام المالية للمشروع والأهداف والعوائد المحتملة.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        البيانات المالية والأدوات
      </Typography>
      <Typography variant='body1'>
        توفر البيانات المالية والأدوات طرقًا، مع الرسوم البيانية، لتوضيح وتخزين
        المعلومات المالية. يتيح ذلك لأصحاب المشروع والمساهمين تتبع الأداء المالي
        واتخاذ قرارات مدروسة بشأن مستقبل المشروع.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        إدارة الحصص
      </Typography>
      <Typography variant='body1'>
        إدارة الحصص هي لإدارة الحصص بين المساهمين. تتيح لأصحاب المشروع
        والمساهمين تتبع وإدارة الحصص والتأكد من تخصيصها بشكل مناسب بناءً على
        مستوى إسهام كل مساهم في المشروع.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        الغرف
      </Typography>
      <Typography variant='body1'>
        تحتوي المشروع على ثلاثة أنواع من الغرف: الغرفة الرئيسية للمشروع بأكمله،
        وغرفة القسم لأعضاء القسم فقط، وغرفة القسم الفرعي للأقسام الفرعية في أي
        قسم. تسمح الغرف بالمناقشات وتصفح تاريخ المناقشات، مما يتيح لأعضاء
        المشروع التعاون وتبادل المعلومات بشكل فعال.
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        الأقسام
      </Typography>
      <Typography variant='body1'>
        يحتوي المشروع أيضًا على أقسام لإدارة المساهمين والأقسام الفرعية داخل كل
        قسم، وذلك بدعم من أنظمة إدارة المستندات والمنتجات والمهام. يمكن
        للمستخدمين أيضًا إدارة الاجتماعات والتصويتات والطلبات داخليًا وخارجيًا،
        وإدارة المساهمات مع مشاريع أخرى. بالإضافة إلى ذلك، توفر المنصة العديد من
        قوالب نماذج الأعمال وجوانب استراتيجية التسويق لمساعدة المستخدمين على
        توحيد أفكارهم وتطوير خطط المشروع بشكل فعال.
      </Typography>
    </div>
  )
  const rtl = document.body.dir === 'rtl'
  return rtl ? Arabic : English
}

export default ProjectsElements
