import React from 'react'
import { Typography } from '@mui/material'
import useStyles from '../styles'

const ChatsSystem = () => {
  const classes = useStyles()
  const English = (
    <div className={classes.root}>
      <Typography variant='h2' className={classes.title}>
        Chat System
      </Typography>
      <Typography variant='body1'>
        The chat system allows users to send text messages to each other. Users
        can also send images, links, and hashtags. The chat system includes a
        library of images and mentioned elements.
      </Typography>
    </div>
  )

  const Arabic = (
    <div className={classes.root}>
      <Typography variant='h2' className={classes.title}>
        نظام الدردشة
      </Typography>
      <Typography variant='body1'>
        يتيح نظام الدردشة للمستخدمين إرسال رسائل نصية لبعضهم البعض. يمكن
        للمستخدمين أيضًا إرسال الصور والروابط والهاشتاجات. يحتوي نظام الدردشة
        على مكتبة للصور والعناصر المذكورة.
      </Typography>
    </div>
  )
  const rtl = document.body.dir === 'rtl'
  return rtl ? Arabic : English
}

export default ChatsSystem
