import React, { useRef, useState } from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Container,
  Drawer,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Menu as MenuIcon } from '@material-ui/icons'
import { Home as HomeIcon } from '@material-ui/icons'
import HutupiaGuidePageSidebar from './HutupiaGuidePageComps/HutupiaGuidePageSidebar'
import HutupiaGuidePageConfigs from './HutupiaGuidePageComps/HutupiaGuidePageConfigs'
import { useParams } from 'react-router-dom'

const drawerWidth = 250

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    zIndex: theme.zIndex.drawer - 1,
    flexShrink: 0,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))

const HutupiaGuidePage = () => {
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { sectionInd, subSectionInd } = useParams()

  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  const [activeSection, setActiveSection] = useState(Number(sectionInd) || 0)
  const [activeSubsection, setActiveSubsection] = useState(
    Number(subSectionInd) || 0
  )

  const handleSectionClick = (sectionId) => {
    setActiveSection(sectionId)
    setActiveSubsection(0)
  }
  const currenSection = HutupiaGuidePageConfigs?.find(
    (s) => s?.id === activeSection + 1
  )
  const activeSubsectionRef = useRef(
    Array.from({ length: currenSection?.subtitles?.length }, () => null)
  )

  const handleSubsectionClick = (subsectionId) => {
    setActiveSubsection(subsectionId)
    if (activeSubsectionRef.current[subsectionId - 1]) {
      setTimeout(() => {
        activeSubsectionRef.current[subsectionId - 1].scrollIntoView({
          behavior: 'smooth',
        })
      }, 0)
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
  const rtl = document.body.dir === 'rtl'
  return (
    <>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='menu'
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <div style={{ flexGrow: 1, textAlign: 'center' }}>
            <Typography
              variant='h6'
              style={{
                fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
              }}
            >
              {rtl ? 'ارشادات هيوتيوبيا' : 'Hutupia Guide'}
            </Typography>
          </div>
          <IconButton
            color='inherit'
            aria-label='home'
            href='https://hutupia.com/home'
          >
            <HomeIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant='temporary'
        anchor={rtl ? 'right' : 'left'}
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <HutupiaGuidePageSidebar
          sections={HutupiaGuidePageConfigs}
          activeSection={activeSection}
          activeSubsection={activeSubsection}
          onSectionClick={handleSectionClick}
          onSubsectionClick={handleSubsectionClick}
          handleDrawerClose={handleDrawerClose}
        />
      </Drawer>
      <main className={classes.content}>
        <Container maxWidth='lg' style={{ marginTop: '6rem' }}>
          <div className='main-content'>
            <h1 id={`section-${activeSection}`}>{currenSection?.title}</h1>
            {currenSection?.subtitles.map((subtitle, index) => (
              <div
                key={subtitle.id}
                ref={(el) => (activeSubsectionRef.current[index] = el)}
              >
                <h2>{subtitle.title}</h2>
                {<p>{subtitle.content}</p>}
              </div>
            ))}
          </div>
        </Container>
      </main>
    </>
  )
}

export default HutupiaGuidePage
