import React from 'react'
import { Typography } from '@mui/material'
import useStyles from '../styles'

const ContributorsAndFollowers = () => {
  const classes = useStyles()
  const English = (
    <div className={classes.root}>
      <Typography variant='h2' className={classes.title}>
        Contributors and Followers
      </Typography>
      <Typography variant='h3' className={classes.subTitle}>
        Contributors
      </Typography>
      <Typography variant='body1'>
        Contribution in Hutupia is all about giving users the ability to
        interact more effectively. This can include sharing in projects,
        contributing ideas, or fulfilling inquiries. Because of the importance
        of these interactions and their serious nature, Hutupia will only allow
        them if both parties involved are included in each other's contributors
        list. The contributors list contains all the users that you have added
        through the "Add Contributor" icon.
      </Typography>
      <Typography variant='h3' className={classes.subTitle}>
        Followers and Followings
      </Typography>
      <Typography variant='body1'>
        Followers and followings work in the same way as they do on other social
        platforms. You can follow other users to receive their updates, and
        others can follow you to receive your updates. This helps to keep you
        connected with the people and projects that matter most to you on
        Hutupia.
      </Typography>
    </div>
  )
  const Arabic = (
    <div className={classes.root}>
      <Typography variant='h2' className={classes.title}>
        المساهمون والمتابعون
      </Typography>
      <Typography variant='h3' className={classes.subTitle}>
        المساهمون
      </Typography>
      <Typography variant='body1'>
        يتعلق المساهمون في هيوتيوبيا بمنح المستخدمين القدرة على التفاعل بشكل
        أكثر فعالية. يمكن أن يشمل ذلك مشاركة في المشاريع وإضافة أفكار أو استيفاء
        الاستفسارات. نظرًا لأهمية هذه التفاعلات وطبيعتها الجادة ، لن يسمح
        هيوتيوبيا بها إلا إذا تم تضمين كلا الطرفين المعنيين في قائمة المساهمين
        الخاصة ببعضهما البعض. تحتوي قائمة المساهمين على جميع المستخدمين الذين
        قمت بإضافتهم من خلال أيقونة "إضافة مساهم".
      </Typography>
      <Typography variant='h3' className={classes.subTitle}>
        المتابعون والمتابعات
      </Typography>
      <Typography variant='body1'>
        يعمل المتابعون والمتابعات بنفس الطريقة التي يعمل بها على منصات التواصل
        الاجتماعي الأخرى. يمكنك متابعة المستخدمين الآخرين لتلقي تحديثاتهم ،
        ويمكن للآخرين متابعتك لتلقي تحديثاتك. هذا يساعد على البقاء على اتصال
        بالأشخاص والمشاريع التي تهمك الأكثر في هيوتيوبيا.
      </Typography>
    </div>
  )
  const rtl = document.body.dir === 'rtl'
  return rtl ? Arabic : English
}

export default ContributorsAndFollowers
